<template>
  <main id="gardner">
    <section class="content">
      <section class="intro">
        <aside class="back" @click="back">
          tillbaka
        </aside>
        <img src="@/assets/icon-gardner.svg" alt="gardner">
      </section>
      <article class="floating">
        <h1>Personlighetstestet</h1>
        <div v-html="content.desc"></div>
        <a href="#" class="btn" @click="startTest">Då kör vi!</a>
      </article>
    </section>
  </main>
</template>

<script>
export default {
  name: 'tests',
  methods: {
    startTest(){
      this.$emit('move', { transitionType: 'start', to: '/test/personlighetstestet/fragor' });
    },
    back(){
      this.$emit('move', { transitionType: 'reveal', to: '/test' });
    }
  },
  computed: {
    content(){
      return this.$store.getters.gardner;
    }
  }
}
</script>

<style lang="scss">
@import '../../scss/variables';

#gardner {
    
  .content {

      .intro {
          position: relative;

          aside {
              background: black;
              position: absolute;
              top: 1rem;
              left: 1rem;
              padding: .25rem .5rem;
              border-radius: $radius;
          }

          img {
            max-width: 12rem;
          }
      }
  }

  .floating {

    div {

      p {
        &:first-child {
          font-size: 1rem;
          line-height: 1.4rem;
          font-weight: 600;
          margin: 0 0 1.4rem 0;
        }
      }
    }
  }
}

</style>

